import { Link } from "react-router-dom";

export default function CompFooter() {
  return (
    <div id="comp-footer">
      <p>&copy; 브레인 OS 연구소 | 서울시 강남구 영동대로 215 일원빌딩 4층</p>
      <p>
        <Link style={{ cursor: "pointer" }} to={"/terms/privacy"}>
          개인정보 취급방침
        </Link>
        &nbsp;/&nbsp;
        <Link style={{ cursor: "pointer" }} to={"/terms/service"}>
          서비스 이용약관
        </Link>
      </p>
    </div>
  );
}
