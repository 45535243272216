import { useContext, useEffect } from "react";
import { BosiContext } from "../constants/BosiContext";

export default function TermsPrivacy() {
  const message = `
  주식회사 (주)브레인OS연구소(이하 '회사'라 함)는 정보통신망 이용촉진 및 정보보호 등에 관한 법률, 개인정보보호법, 통신비밀보호법, 전기통신사업법 등 관련 법령에 따라 정보통신서비스제공자가 준수하여야 할 개인정보보호 규정을 준수하며, 관련 법령에 의거한 개인정보취급방침을 정하여 이용자 권익 보호에 최선을 다하고 있습니다
1.수집하는 개인정보의 항목 및 수집방법

가.수집하는 개인정보의 항목

필수 : 성명, 이메일, 생년월일, 성별, 학번, 전공, BOSI 테스트 결과
나.개인정보 수집방법

회사는 BOSI 테스트 서비스 제공을 위해 다음과 같은 방법으로 개인정보를 수집합니다.
• 서비스 가입이나 사용 중 이용자의 자발적 제공을 통한 수집
• 회원의 선택에 따라 페이스북의 아이디를 이용하여 로그인하는 회원의 경우 해당 협력회사로부터 자동으로 수집
2.개인정보의 수집 및 이용목적

회사의 BOSI 테스트 진행 및 결과 서비스에 관한 전반의 업무에 필요한 개인정보를 수집하고 이용하고자 함.
3.개인정보의 제3자 제공 및 취급위탁

회사는 이용자들의 개인정보를 원칙적으로 “2. 개인정보의 수집목적 및 이용목적”에서 고지한 범위 내에서 사용하며,
•검사자에 한하여 검사자 정보가 뇌적성검사 관리센터인 MSC 브레인컨설팅그룹 관리 페이지로 공유됩니다.
•뇌적성 교육 수강생일 경우 기본자료로 활용될 수 있습니다.
상기 목적 이외에는 이용자의 사전동의 없이 “2. 개인정보의 수집목적 및 이용목적”에서 고지한 범위를 넘어서 이용하거나 원칙적으로 이용자의 개인정보를 외부에 공개하지 않습니다. 단, 아래의 경우에는 예외로 합니다.
•이용자들이 사전에 동의한 경우
•법령의 규정에 의한 경우
4.개인정보의 보유 및 이용기간

회사는 이용자 동의일로부터 서비스를 제공하는 기간 동안에 한하여 이용자의 개인정보를 보유 및 이용하게 됩니다. 이용자의 개인정보는 원칙적으로 개인정보의 수집 및 이용목적이 달성되면 이용자의 개인정보를 파기합니다. 단, 회사 내부방침이나 관계법령의 규정에 의하여 보존할 필요가 있는 경우에는 일정 기간 동안 개인정보를 보관할 수 있습니다.
5.개인정보 파기절차 및 방법

회사의 개인정보 파기절차 및 방법은 다음과 같습니다.
파기절차

이용자가 서비스 이용 등을 위해 입력한 정보는 목적이 달성된 후, 내부 방침 및 기타 관련 법령에 의한 정보보호 사유(보유 및 이용기간 참조)에 따라 일정 기간 저장된 후 파기됩니다. 동 개인정보는 법률에 의한 경우가 아니고서는 보유되는 이외의 다른 목적으로 이용되지 않습니다.
파기방법

종이에 출력된 개인정보는 분쇄기로 분쇄하고, 전자적 파일 형태로 저장된 개인정보는 기록을 재생할 수 없는 기술적 방법을 사용하여 삭제합니다.
6.개인정보보호 업무 관련 문의처

본 개인정보취급방침에 관해 질문/불만/상담 내용이 있는 경우 개인정보관리 개발팀(support@brainosi.com)으로 신고하실 수 있습니다.
7.기타

회사 서비스 내에 링크되어 있는 웹사이트들이 개인정보를 수집하는 행위에 대해서는 본 “브레인OS연구소 개인정보취급방침”이 적용되지 않음을 알려 드립니다.
8.개인정보취급방침의 개정

본 개인정보취급방침은 개정되는 경우가 있습니다. 중요한 변경사항이 있을 때에는 알기 쉬운 방법으로 개정 전에 이용자에게 개정 내용을 공지합니다.

개정일자 : 2016년 02월 01일
제정일자 : 2014년 11월 14일
    `;

  const { setIsFullscreen, setSideNavCategory } = useContext(BosiContext);

  useEffect(() => {
    setIsFullscreen(false);
    setSideNavCategory(null);

    return () => {
      setIsFullscreen(true);
    };
  }, []);

  return (
    <div id="contents">
      <div className="wrapper">
        <div className="container">
          <div className="contents-area">
            <div id="terms">
              <div className="section">
                <h1>개인정보 취급방침</h1>
                <textarea
                  name="term1"
                  className="term-box"
                  defaultValue={message}
                ></textarea>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
