import { GoogleMap, Marker } from "@react-google-maps/api";
import { useContext, useEffect } from "react";
import { BosiContext } from "../constants/BosiContext";

const containerStyle = {
  width: "100%",
  height: "400px",
};

const center = {
  lat: 37.498134,
  lng: 127.068524,
};

export default function Contact() {
  const { setIsFullscreen, setSideNavCategory } = useContext(BosiContext);

  useEffect(() => {
    setIsFullscreen(false);
    setSideNavCategory(null);

    return () => {
      setIsFullscreen(true);
    };
  }, []);
  return (
    <div id="contact">
      <div id="section-1" className="section">
        <h1>Contact</h1>
        <GoogleMap mapContainerStyle={containerStyle} center={center} zoom={16}>
          <Marker position={center} clickable={false} draggable={false} />
        </GoogleMap>
        <h5>주소</h5>
        <p>서울시 강남구 영동대로 215 일원빌딩 4층</p>
        <h5>이메일</h5>
        <p>support@brainosi.com</p>
        <h5>전화번호</h5>
        <p>(02) 539-2066</p>
      </div>
    </div>
  );
}
