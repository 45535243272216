class APIs {
  async getHistoryList(code) {
    const option = { method: "GET" };
    const response = await fetch(
      process.env.REACT_APP_API_BASE_URL + `/history/${code}`,
      option
    );
    return await response.json();
  }

  async getClientList(category) {
    const option = { method: "GET" };
    const response = await fetch(
      process.env.REACT_APP_API_BASE_URL + `/client/${category}`,
      option
    );
    return await response.json();
  }

  async getPressList(page, size) {
    const option = { method: "GET" };
    const response = await fetch(
      process.env.REACT_APP_API_BASE_URL + `/board/press/${page}/${size}`,
      option
    );
    return await response.json();
  }

  async getPressDetail(postId) {
    const option = { method: "GET" };
    const response = await fetch(
      process.env.REACT_APP_API_BASE_URL + `/board/press/${postId}`,
      option
    );
    return await response.json();
  }
}

export default new APIs();
