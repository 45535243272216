import React, { useContext, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

import { HISTORY } from "../constants/history";
import { BosiContext } from "../constants/BosiContext";

import APIs from "../apis/APIs";

function TabItem(props) {
  return (
    <li className={props.isTabActive ? "active" : ""}>
      <a onClick={() => props.handleClick()}>{props.title}</a>
    </li>
  );
}

function HistoryList(props) {
  if (!props.list) return null;

  const listElement = props.list.map((history, idx) => {
    return (
      <tr key={idx}>
        <th>{history.years}</th>
        <td dangerouslySetInnerHTML={{ __html: history.content }}></td>
      </tr>
    );
  });

  return (
    <table>
      <tbody>{listElement}</tbody>
    </table>
  );
}

export default function Intro() {
  const [activeTab, setActiveTab] = useState("research");
  const [historyList, setHistoryList] = useState([]);
  const [searchParams] = useSearchParams();
  const { setIsFullscreen, setSideNavIndex, setSideNavCategory } =
    useContext(BosiContext);

  const findPosition = () => {
    const sections = document.getElementsByClassName("section");
    const sectionCnt = sections.length;
    for (var idx = 1; idx <= sectionCnt; idx++) {
      const section = document.getElementById(`section-${idx}`);
      const offset = section.getBoundingClientRect().bottom;
      if (offset > 20) {
        setSideNavIndex(idx - 1);
        break;
      }
    }
  };

  useEffect(() => {
    APIs.getHistoryList(HISTORY.CODE[activeTab]).then(({ historyList }) => {
      setHistoryList(historyList);
    });

    document.addEventListener("scroll", findPosition);
    setIsFullscreen(false);
    setSideNavCategory("intro");

    const navIndex = searchParams.get("idx");
    if (navIndex > 0) {
      document.getElementById(`section-${navIndex}`).scrollIntoView();
    }

    return () => {
      document.removeEventListener("scroll", findPosition);
      setIsFullscreen(true);
      setSideNavCategory(null);
    };
  }, [activeTab]);

  return (
    <div id="intro">
      <div id="section-1" className="section">
        <h1>브레인 OS 연구소 소개</h1>
        <p>
          브레인OS연구소는 21세기가 뇌 과학의 시대임을 예견하고 2003년 교육
          분야를 시작으로 2008년 기업 컨설팅 분야에, 2014년 IT 분야로 연구영역을
          확대해온 &lsquo;뇌인지 연구소&rsquo;입니다.
        </p>

        <p>
          2004년 국내외 최초로 학생용 뇌적성검사 BOSI를 개발하였습니다.
          BOSI(Brain Orientation Suitability Inventory)는 뇌인지에 심리학, 철학,
          교육학 등 뇌 과학과 관련된 세계적인 연구를 바탕으로 인간의 뇌인지
          유형을 8,192종류로 분류하여 체계화한 검사로서, 기존의 적성검사로는 알
          수 없었던 자신의 성격이나 행동양식의 원인을 알 수 있을 뿐만 아니라
          타고난 적성과 후천적으로 길러진 적성까지도 파악하여 자신의 적성을
          입체적으로 정확하게 알 수 있는 검사입니다.
        </p>

        <p>
          2004년 MSC브레인컨설팅그룹을 설립하여 교육 분야에 학생용 BOSI를
          적용하기 시작한 이 후로 수많은 교육기관에서 학생의 적성파악, 진로탐색,
          전공적합도 등 다양한 교육 활동에 사용되었습니다. 또한 대학연구소와
          의료기관과의 공동연구, 공동프로젝트를 통하여 인간의 뇌인지를 검증,
          활용하는 작업을 진행하였습니다.
        </p>

        <p>
          2008년에는 성인용 뇌적성 검사인 IPJP(Identity, Potentiality, Job
          matching, People matching)를 개발하여 기업 컨설팅 분야에 적용하게
          되었습니다. 그 결과, 한국 기업뿐만 아니라 중국과 미국의 많은
          기업들에게 뇌인지 HR 시스템을 지원하여 직원 선발, 배치, 팀 프로젝트,
          임원 코칭에서 효율적인 인적관리의 효과를 얻었습니다. 2015년에는
          스타트업이 성공하도록 지원하는 &lsquo;스타트업 브레인코칭&rsquo;
          프로그램을 개발하여 2016년 정부기관인 문화창조융합본부,
          문화창조아카데미, 서울산업진흥원에서 스타트업 회사들을 대상으로
          프로그램을 진행하였습니다.
        </p>

        <p>
          2014년에는 그 동안 축적된 뇌인지 연구를 기반으로 IT 분야에 진출하여
          브레인OS연구소를 법인화하였습니다. 그 결과 2016년에 &lsquo;Dream
          DNA&rsquo; 교육용 앱을 개발하여 학생들이 자신의 뇌적성을 찾고, 부모는
          자녀의 잠재된 창의성을 잘 키울 수 있도록 지원하였습니다. 2015년에는
          미국 실리콘벨리에 BestLink Intelligence를 창업하여 KIC(Korean
          Innovation Center) 실리콘밸리 지부에 정식 입주하였고, 2016년 소셜
          매칭앱 &lsquo;3 o&rsquo;clock&rsquo;을 미국에서 개발하였습니다.
        </p>

        <p>
          브레인OS연구소는 2016년부터 4차산업혁명의 주축인 뇌인지 퍼스널
          인공지능(AI)을 개발하여 학생용 뇌적성검사 BOSI와 성인용 뇌적성검사
          IPJP와 결합하여 인간의 행동을 더욱 정확하게 예측하기 위한 지속적인
          연구를 진행하고 있습니다.
        </p>
      </div>
      <div id="section-2" className="section">
        <h1>인사말</h1>
        <p>
          자신의 뇌의 스위트 스팟(sweet spot)이 어딘지를 알고 있는지요? 거기를
          알고 때려야 그 동안 잠자고 있던 뇌 본능이 깨어나고 인생과 사업이
          성공을 향해 쭉쭉 뻗어가기 시작할 것입니다.
        </p>

        <p>
          경영도 마찬가지입니다. 다른 사람 뇌의 스위트 스팟을 찾아서 때리는
          작업이지요. 직원들을 춤추기 시작하고, 성과는 하늘 높이 치솟을
          것입니다. 더 나아가 직원들의 뇌의 스위트 스팟을 연결시켜 보세요. 수퍼
          컴퓨터를 만들 듯 수퍼 브레인을 만들어 보세요. 조직 창의성이
          극대화되면서 성과도, 조직문화도 최고가 될 것입니다.
        </p>

        <p>
          결국 해답은 뇌 안에 있습니다. 세상에 나쁜 뇌, 좋은 뇌는 없습니다. 단지
          적합한 뇌만 있을 뿐입니다.
        </p>

        <p>
          당신의 뇌는 어떤 일에 적합할까요? 당신과 시너지를 내는 파트너는
          누구일까요? 당신 안에 잠들어 있는 뇌본능은 무었일까요? 인류문명의
          마지막 미개척지가 바로 뇌입니다. 가늠할 수 없는 꿈과 가능성이 살아
          숨쉬는 신대륙입니다. 그곳에 엄청난 기회가 여러분을 기다리고 있습니다.
        </p>

        <p>뇌를 아는 것이 인생과 사업에서 승리하는 비결입니다.</p>

        <p className="text-right">
          브레인OS연구소 대표 <strong>안진훈</strong>
        </p>
      </div>
      <div id="section-3" className="section">
        <h1>히스토리</h1>

        <div className="tab">
          <ul>
            <TabItem
              title={"교육연구"}
              isTabActive={activeTab === "research"}
              handleClick={() => setActiveTab("research")}
            />
            <TabItem
              title={"기업컨설팅"}
              isTabActive={activeTab === "consulting"}
              handleClick={() => setActiveTab("consulting")}
            />
            <TabItem
              title={"뇌인지IT"}
              isTabActive={activeTab === "it"}
              handleClick={() => setActiveTab("it")}
            />
          </ul>
        </div>

        <HistoryList list={historyList} />
      </div>
    </div>
  );
}
