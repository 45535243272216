import React from "react";

export const BosiContext = React.createContext({
  isFullscreen: true,
  sideNavIndex: 0,
  sideNavCategory: "",
  setIsFullscreen: () => {},
  setSideNavIndex: () => {},
  setSideNavCategory: () => {},
});
