import { useContext, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

import { BosiContext } from "../constants/BosiContext";
import img from "../assets/img-1.png";
import img2 from "../assets/img-2.png";
import img3 from "../assets/img-3.png";

function ProcessDescTab(props) {
  if (props.activeTab === "first") {
    return <FirstTab />;
  } else if (props.activeTab === "second") {
    return <SecondTab />;
  }
  return null;
}

function FirstTab() {
  return (
    <table>
      <tbody>
        <tr>
          <td>
            <span>1. 전화나 이메일로 신청</span>
            (02) 539-2066 이나 support@brainosi.com 으로 신청하시면 됩니다.
          </td>
        </tr>
        <tr>
          <td>
            <span>2. 검사지 발송</span>
            검사자료는 검사 전에 우편이나 택배로 배송됩니다.
          </td>
        </tr>
        <tr>
          <td>
            <span>3. 검사 실시</span>
            검사 실시에 대한 자세한 안내서를 별도로 보내드리니 참조하시기
            바랍니다.
          </td>
        </tr>
        <tr>
          <td>
            <span>4. 답안지 회수</span>
            회수용 봉투에 응시 인원수를 적어 답안지와 검사지를 함께 넣어 본사로
            보내주시면 됩니다.
          </td>
        </tr>
        <tr>
          <td>
            <span>5. 결과 출력</span>
            결과분석 팀이 결과분석 후 결과지를 출력합니다.
          </td>
        </tr>
        <tr>
          <td>
            <span>6. 검사결과지 발송</span>
            검사결과지는 우편이나 이메일을 통하여 발송합니다.
          </td>
        </tr>
        <tr>
          <td>
            <span>7. 해석 및 안내</span>
            담당 선생님용 해설서는 이메일로 보내드립니다. 결과해석을 위한
            학생강의나 교사안내는 별도로 문의 바랍니다.
          </td>
        </tr>
      </tbody>
    </table>
  );
}

function SecondTab() {
  return (
    <table>
      <tbody>
        <tr>
          <td>
            <span>1. 전화나 팩스로 신청</span>
            (02) 539-2066 이나 support@brainosi.com 으로 신청하시면 됩니다.
          </td>
        </tr>
        <tr>
          <td>
            <span>2. 모바일용 URL 발송</span>
            검사대상자에게 모바일용 URL을 보냅니다.
          </td>
        </tr>
        <tr>
          <td>
            <span>3. 모바일로 검사 실시</span>
            검사자는 모바일을 통하여 검사를 진행합니다.
          </td>
        </tr>
        <tr>
          <td>
            <span>4. 결과 출력</span>
            결과분석 팀이 결과분석 후 결과지를 출력합니다.
          </td>
        </tr>
        <tr>
          <td>
            <span>5. 검사결과지 발송</span>
            검사결과지는 우편이나 이메일을 통하여 발송합니다.
          </td>
        </tr>
        <tr>
          <td>
            <span>6. 해석 및 안내</span>
            결과해석을 위한 강의나 안내는 별도로 문의 바랍니다.
          </td>
        </tr>
      </tbody>
    </table>
  );
}

export default function BOSI() {
  const [activeTab, setActiveTab] = useState("first");
  const [searchParams] = useSearchParams();
  const { setIsFullscreen, setSideNavIndex, setSideNavCategory } =
    useContext(BosiContext);

  const findPosition = () => {
    const sections = document.getElementsByClassName("section");
    const sectionCnt = sections.length;
    for (var idx = 1; idx <= sectionCnt; idx++) {
      const section = document.getElementById(`section-${idx}`);
      const offset = section.getBoundingClientRect().bottom;
      if (offset > 20) {
        setSideNavIndex(idx - 1);
        break;
      }
    }
  };

  useEffect(() => {
    document.addEventListener("scroll", findPosition);
    setIsFullscreen(false);
    setSideNavCategory("bosi");

    const navIndex = searchParams.get("idx");
    if (navIndex > 0) {
      document.getElementById(`section-${navIndex}`).scrollIntoView();
    }

    return () => {
      document.removeEventListener("scroll", findPosition);
      setIsFullscreen(true);
      setSideNavCategory(null);
    };
  }, []);

  return (
    <div id="bosi">
      <div id="section-1" className="section">
        <h1>뇌인지 적성검사 소개</h1>
        <p style={{ marginTop: "3em" }}>
          뇌인지 적성검사(BOSI, Brain Orientation Suitability Inventory)는
          브레인OS연구소가 국내외 최초로 인간의 사고와 행동의 근원인 뇌를
          기반으로 하여 만든 뇌인지 적성검사로서, 피검사자의 타고난 뇌 성향을
          분석하여 &quot;자신의 뇌가 어떤 타입이며, 어떤 일에 적합하고, 어떤
          사람과 잘 어울리는지&quot; 알아보는 검사입니다.
        </p>

        <p>
          본 연구소는1981년 노벨상을 수상한 로저 스페리(R. Sperry)의 좌우뇌
          분할이론을 뇌의 인지구조와 연계하여 사람들이 왜 다르게 생각하는지 그
          원인을 규명하였습니다. 또 생각의 뇌인 전뇌와 감각의 뇌인 후뇌를
          인지프로세스와 연계하여 인간이 외부의 대상을 어떠한 방식으로 느끼고
          이해하는지 그 과정을 밝혔습니다. 아울러 감정의 뇌인 대뇌변연계와
          인지의 뇌인 대뇌신피질의 상호관계 연구를 통해 인간의 인지행동의 근원에
          대한 이해를 정립하였습니다.
        </p>

        <p>
          이러한 연구결과를 바탕으로 서로 다른 뇌인지 접근방법을 하나로 통합하여
          3차원적 뇌인지 모델을 구축하였으며, 여기에 인지과학, 심리학, 철학,
          교육학 등 뇌 과학과 관련된 세계적인 연구를 바탕으로 인간의 뇌인지
          유형을 8,192 가지로 분류하고 체계화 하였습니다.
        </p>

        <p>
          이러한 뇌 과학과 관련된 이론적인 측면과 함께 지난 10년 동안
          500,000건이 넘는 임상검사와 추적상담을 통하여 검사의 정확성을 높이기
          위한 신뢰성과 타당성을 검증하는 연구를 지속적으로 수행하였습니다. 또한
          인공지능(AI)과 결합하여 기존의 심리검사로는 알 수 없었던 성격이나
          행동양식의 근원적인 원인을 알 수 있을 뿐만 아니라, 타고난 적성과
          후천적으로 길러진 적성도 파악하여 피검사자의 적성을 입체적으로
          정확하게 이해할 수 있는 새롭고 획기적인 검사입니다.
        </p>
      </div>

      <div id="section-2" className="section">
        <h1>타 심리검사와 차이점</h1>
        <ul className="diff">
          <li>
            <img src={img} width="100" height="auto" alt="" />
            <h2>인간의 다양성 분류</h2>
            <p>
              기존의 심리검사로는 인간을 분류하는데 한계가 있습니다. 예를 들면
              MBTI는 사람의 성격유형을 16가지로, DISC는 4가지 유형으로
              분류합니다. 그러나 인간을 이러한 몇 가지 분류만으로 설명하기에는
              너무나 많은 한계가 있습니다. BOSI는 인간의 유형을 8,192유형으로
              구분하여 설명하므로 다른 어떤 검사보다 각 개인의 성향을 정확히 알
              수 있는 검사입니다.
            </p>
          </li>
          <li>
            <img src={img3} width="100" height="auto" alt="" />
            <h2>인간의 행동양식 규명</h2>
            <p>
              기존의 심리검사는 개인의 심리와 행동의 결과만을 설명할 뿐이어서
              사람이 왜 그렇게 생각하고 행동하는지 그 원인을 알 수가 없습니다.
              BOSI는 국내외 최초로 인간의 사고와 행동의 근원인 뇌를 기반으로
              인간의 인지패턴과 행동양식의 원인을 규명하여 그 사람이 왜 그런
              생각과 행동을 하는지 그 원인을 설명할 수 있습니다.
            </p>
          </li>
          <li>
            <img src={img2} width="100" height="auto" alt="" />
            <h2>미래행동의 예측</h2>
            <p>
              기존의 심리검사는 현재의 심리와 행동만을 알 수 있습니다. 따라서 그
              사람의 타고난 원래의 적성이나 미래예측은 알 수가 없습니다. BOSI는
              사람의 타고난 적성이 환경에 의해서 어떻게 현재의 상태로
              변형되었는지 알 수 있을 뿐 아니라 그 사람이 어떠한 상황에서 어떤
              선택을 할 가능성이 높은지 미래예측이 상당 부분 가능한 검사입니다.
            </p>
          </li>
        </ul>
      </div>

      <div id="section-3" className="section clearfix">
        <h1>검사의 종류</h1>
        <h2>
          BOSI 진로적성탐색검사 <span>(초4,5,6학년용)</span>
        </h2>
        <p>
          초등학생용 검사로서 자신의 타고난 뇌인지 성향을 분석하여 자신에게
          적합한 진로와 적성이 무엇인지 탐색하는 검사입니다.
        </p>
        <h2>
          BOSI 학습진로적성검사 <span>(중1,2,3학년용)</span>
        </h2>
        <p>
          중학생용 검사로서 자신의 타고난 뇌인지 성향을 분석하여 자신에게 적합한
          진로적성이 무엇인지 알아보고, 또 자신에게 맞는 학습방법은 무엇인지
          탐색하는 검사입니다.
        </p>
        <h2>
          BOSI 전공진로적성검사 <span>(고1,2,3학년용)</span>
        </h2>
        <p>
          고등학생용 검사로서 자신의 타고난 뇌인지 성향을 분석하여 자신에게 가장
          적합한 전공과 직업은 무엇인지 알아보는 검사입니다.
        </p>
        <h2>
          IPJP 적성검사 <span>(대학생 및 성인용)</span>
        </h2>
        <p>
          대학생 및 성인용 검사로서 자신의 타고난 뇌인지 성향을 분석하여 나는
          누구이며, 잠재능력은 무엇인지, 어떤 일에 가장 적합하고, 어떤 사람과 잘
          어울리는지 알아보는 검사입니다.
        </p>
      </div>

      <div id="section-4" className="section">
        <h1>학생용 검사(BOSI)의 활용</h1>
        <h2 style={{ marginTop: "3em" }}>잠재역량 파악</h2>
        <p>
          학생의 타고난 뇌인지 성향을 분석하여 숨겨진 잠재역량이 무엇인지
          찾아줍니다. 이를 기반으로 지식기반 사회에서 학생의 강점은 살리고,
          약점은 보완하는 방법을 알려줌으로써 자신과 학교생활의 목표를 이룰 수
          있도록 합니다.
        </p>
        <h2>안정적 또래관계 구축</h2>
        <p>
          학생의 뇌인지가 어떤 사람과 시너지를 내고 어떤 사람과는 갈등을 일으킬
          수 있는지를 찾아줍니다. 따라서 또래관계에서 발생할 수 있는
          갈등(학교폭력, 왕따 등)을 최소화하여 학교생활에서 안정적인 인간관계를
          맺도록 합니다.
        </p>
        <h2>진로적성 탐색</h2>
        <p>
          학생의 뇌인지 성향을 분석하여 가장 적합한 진로적성이 무엇인지
          알려줍니다. 이를 토대로 학생의 흥미가 무엇인지, 장차 어떤 전공이 가장
          적합한지, 어떤 과목에 흥미를 보이고 어떤 과목은 문제가 있을 수
          있는지를 파악할 수 있어 진로적성을 탐색하고 학습효율을 높일 수
          있습니다.
        </p>
        <h2>효율적 학생지도</h2>
        <p>
          교사가 학생을 오랜 시간 경험하지 않아도 수 년 동안 경험한 학생처럼
          파악할 수 있으며 학생의 현 성향뿐 아니라, 어떠한 상황에서 어떤 행동을
          할 가능성이 높은지 미래행동을 상당 부분 예측할 수 있어 효율적인
          학생지도가 가능하도록 합니다. 또한 진로 및 상담교사, 각 과목 담당교사,
          담임교사가 필요로 하는 특수한 정보들도 필요한 부분만을 선택하여 손쉽게
          사용할 수 있도록 설계되어 있어서, 여러 검사들을 검사해야 하는 시간적,
          경제적 불편을 최소화하였습니다.
        </p>
      </div>

      <div id="section-5" className="section">
        <h1>성인용 검사(IPJP)영역과 활용</h1>
        <h2 style={{ marginBottom: 0, marginTop: "3em" }}>검사 영역</h2>
        <ul className="usage">
          <li>
            <h3>Identity - 정체성</h3>
            <p>
              나는 누구이고, 또 나는 어떤 사람일까요? 모든 사람은 자신만의
              뇌성향을 가지고 있어서, 그 뇌성향에 따라 사람마다 느끼고 생각하고
              행동하는 방식이 다릅니다. 자신의 뇌성향을 분석하여 나의 정체성이
              무엇인지 알 수 있도록 합니다.
            </p>
          </li>
          <li>
            <h3>Potentiality - 잠재역량</h3>
            <p>
              나의 숨겨진 잠재력은 무엇일까요? 누구나 자신의 내면에는 위대한
              거인이 잠들어 있습니다. 자신의 타고난 뇌인지 성향을 분석하여
              숨겨진 잠재역량이 무엇인지 찾아줍니다. 이를 기반으로 나의 강점은
              살리고, 약점은 보완하는 방법을 찾을 수 있도록 합니다.
            </p>
          </li>
          <li>
            <h3>Job matching - 업무적합성</h3>
            <p>
              나에게 적합한 일을 무엇일까요? 내가 좋아하는 일을 해야 잘할 수
              있고, 그 분야에서 최고의 전문가가 될 수 있습니다. 나에게 적합한
              일은 무엇인지, 또 어떤 업무에 적합한지 찾을 수 있도록 하여 업무의
              효율성을 극대화할 수 있도록 합니다.
            </p>
          </li>
          <li>
            <h3>People matching - 관계적합성</h3>
            <p>
              나에게 잘 어울리는 사람은 누구일까요? 나에게 최적의 파트너를 찾는
              것이 무엇보다 중요합니다. 나의 뇌가 어떤 사람과 시너지를 내고,
              어떤 사람과는 갈등을 일으키는지 찾아내어 최고의 성과를 낼 수 있는
              창의적인 팀, 창의적인 조직문화를 만드는데 기여합니다.
            </p>
          </li>
        </ul>
        <h2 style={{ marginBottom: 0, marginTop: "3em" }}>검사의 활용</h2>
        <ul className="usage">
          <li>
            <h3>진로 및 취업설계 활용</h3>
            <p>
              자신의 타고난 적성과 현재의 전공을 연계하여, 앞으로 꼭 보완해야 할
              부분은 무엇이지 파악할 수 있도록 하므로 효율적인 진로 및
              취업설계가 가능하도록 합니다.
            </p>
          </li>
          <li>
            <h3>기업 채용 활용</h3>
            <p>
              잠재역량을 파악하여 기업에 맞는 핵심인재를 정확하게 선발할 수
              있으며, 선발된 인재를 적재적소에 배치할 수 있어 업무의 효율성을
              크게 높이고 핵심인재의 이직을 미연에 방지할 수 있습니다.
            </p>
          </li>
          <li>
            <h3>창의적 팀 구성 활용</h3>
            <p>
              팀원들의 뇌가 서로 시너지를 발휘하도록 창의적인 팀 구성을 할 수
              있도록 합니다. 따라서 기업 및 스타트업 뿐 아니라 모든 조직에서
              최고의 성과를 낼 수 있는 팀구성이 가능하도록 하여 성공적인 팀
              프로젝트가 진행될 수 있도록 합니다.
            </p>
          </li>
          <li>
            <h3>창업 시 활용</h3>
            <p>
              나에게 맞는 아이템은 무엇이며, 또 나와 잘 맞고 시너지를 낼 수 있는
              공동창업자는 누구인지 찾을 수 있도록 합니다. 따라서 창업 시 가장
              어려운 이러한 문제들을 해결할 수 있도록 하여 창업의 성공률을 크게
              높일 수 있도록 합니다.
            </p>
          </li>
        </ul>
      </div>
      <div id="section-6" className="section">
        <h1>검사 진행 절차</h1>

        <div className="tab">
          <ul>
            <li className={activeTab === "first" ? "active" : ""}>
              <a onClick={() => setActiveTab("first")}>
                초중고용 검사 진행절차
              </a>
            </li>
            <li className={activeTab === "second" ? "active" : ""}>
              <a onClick={() => setActiveTab("second")}>
                대학생 및 성인용 검사 진행절차
              </a>
            </li>
          </ul>
        </div>

        <ProcessDescTab activeTab={activeTab} />
      </div>
    </div>
  );
}
