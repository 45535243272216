import { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { BosiContext } from "../constants/BosiContext";
import APIs from "../apis/APIs";

export default function PressDetail() {
  const { postId } = useParams();
  const { setIsFullscreen, setSideNavCategory } = useContext(BosiContext);
  const navigate = useNavigate();

  const [title, setTitle] = useState("");
  const [pressName, setPressName] = useState("");
  const [content, setContent] = useState("");

  useEffect(() => {
    APIs.getPressDetail(postId).then((data) => {
      setTitle(data.title);
      setPressName(data.press_NAME);
      setContent(data.content_TAG);
    });

    setIsFullscreen(false);
    setSideNavCategory(null);

    return () => {
      setIsFullscreen(true);
    };
  }, []);

  return (
    <div id="press-detail">
      <h1>보도자료</h1>
      <div className="press-detail">
        <table>
          <thead>
            <tr>
              <th>{title}</th>
            </tr>
            <tr>
              <td>{pressName}</td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <p dangerouslySetInnerHTML={{ __html: content }}></p>
              </td>
            </tr>
          </tbody>
        </table>
        <a className="to-list" onClick={() => navigate(-1)}>
          목록으로 가기
        </a>
      </div>
    </div>
  );
}
