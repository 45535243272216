import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { BosiContext } from "../constants/BosiContext";
import APIs from "../apis/APIs";

function PressList(props) {
  if (!props.list) return null;

  return props.list.map((press, index) => {
    return (
      <li key={index} onClick={() => props.handlePressClick(press.postid)}>
        {press.url !== null && (
          <div
            className="img"
            style={{ backgroundImage: `url(${press.url})` }}
          ></div>
        )}
        <a>
          <div className="title">{press.title}</div>
          <div className="source">{press.press_NAME}</div>
          <div className="brief">{press.preview}</div>
        </a>
      </li>
    );
  });
}

function Pagination(props) {
  if (!props.totalItemCount || !props.pagePerItemCount)
    return <li className="active">1</li>;

  const maxPage = Math.ceil(props.totalItemCount / props.pagePerItemCount);
  const pageRange = getPageRange(props.currentPage, maxPage);
  const rangeElement = pageRange.map((page, index) => {
    return (
      <li
        key={index}
        className={props.currentPage === index ? "active" : ""}
        onClick={() => props.handlePageNumClick(page)}
      >
        {page + 1}
      </li>
    );
  });

  return (
    <>
      <li
        className={"prev" + (props.currentPage !== 0 ? "active" : "")}
        style={{ cursor: props.currentPage === 0 ? "not-allowed" : "" }}
        onClick={() => props.handlePageNumClick(props.currentPage - 1)}
      >
        &lsaquo;
      </li>
      {rangeElement}
      <li
        className={"next" + (props.currentPage + 1 !== maxPage ? "active" : "")}
        style={{
          cursor: props.currentPage + 1 === maxPage ? "not-allowed" : "",
        }}
        onClick={() => props.handlePageNumClick(props.currentPage + 1)}
      >
        &rsaquo;
      </li>
    </>
  );
}

const getPageRange = (currentPage, maxPage) => {
  var start = currentPage - 6;
  if (start < 1) start = 0;

  var end = start + 9;
  if (end > maxPage) end = maxPage;

  if (end - 9 >= 1) start = end - 9;

  const range = [];
  for (var i = start; i < end; i++) {
    range.push(i);
  }

  if (range.length === 0) range.push(1);

  return range;
};

const handlePageNumClick = (pageState, pageNum) => {
  pageState(pageNum);
};

export default function Press() {
  const [pressList, setPressList] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalItemCount, setTotalItemCount] = useState(0);
  const { setIsFullscreen, setSideNavCategory } = useContext(BosiContext);

  const pagePerItemCount = 10;
  const navigate = useNavigate();

  useEffect(() => {
    APIs.getPressList(currentPage, pagePerItemCount).then((data) => {
      setPressList(data.pressList);
      setTotalItemCount(data.total_length);
    });

    setIsFullscreen(false);
    setSideNavCategory(null);

    return () => {
      setIsFullscreen(true);
    };
  }, [currentPage]);
  return (
    <div id="press">
      <div id="section-1" className="section">
        <h1>보도자료</h1>
        <ul className="press-list">
          <PressList
            list={pressList}
            handlePressClick={(postId) => navigate(`/press/${postId}`)}
          />
        </ul>
        <ul className="pagenation">
          <Pagination
            pagePerItemCount={pagePerItemCount}
            currentPage={currentPage}
            totalItemCount={totalItemCount}
            handlePageNumClick={handlePageNumClick.bind(this, setCurrentPage)}
          />
        </ul>
      </div>
    </div>
  );
}
