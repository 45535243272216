import React from "react";
import { Link } from "react-router-dom";

export default function Main() {
  return (
    <div id="main">
      <ul>
        <li>
          <h2>Brain OS Institute</h2>
          <h3>연구소 소개</h3>
          <p>
            브레인 OS 연구소는 인간의 행동을 더욱 정확하게 예측하기위한 지속적인
            연구를 진행하고 있습니다.
          </p>
          <Link to={"/intro"}>자세히 보기</Link>
        </li>
        <li>
          <h2>History</h2>
          <h3>연혁</h3>
          <p>
            2003년부터 교육연구분야, 기업컨설팅 분야, 뇌인지 IT 분야에 이르는
            브레인OS연구소의 히스토리입니다.
          </p>
          <Link to={"/intro?idx=3"}>자세히 보기</Link>
        </li>
        <li>
          <h2>BOSI</h2>
          <h3>학생용 뇌인지 적성검사</h3>
          <p>
            BOSI(Brain Orientation Suitability Iventory) 검사는 초/중/고 학생의
            진로와 적성이 무엇인지 알아보는 검사입니다.
          </p>
          <Link to={"/bosi?idx=4"}>자세히 보기</Link>
        </li>
        <li>
          <h2>IPJP</h2>
          <h3>성인용 뇌인지 적성검사</h3>
          <p>
            IPJP(Identity, Potentiality, Job matching, People matching) 검사는
            대학생 및 성인용검사입니다.
          </p>
          <Link to={"/bosi?idx=5"}>자세히 보기</Link>
        </li>
      </ul>
    </div>
  );
}
