import React, { useState } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import CompHeader from "./layout/CompHeader";
import CompFooter from "./layout/CompFooter";
import CompSideNav from "./layout/CompSideNav";

import Main from "./pages/Main";
import Intro from "./pages/Intro";
import BOSI from "./pages/Bosi";
import Client from "./pages/Client";
import Press from "./pages/Press";
import PressDetail from "./pages/PressDetail";
import Contact from "./pages/Contact";
import TermsPrivacy from "./pages/TermsPrivacy";
import TermsService from "./pages/TermsService";

import { BosiContext } from "./constants/BosiContext";

function FullscreenLayout(props) {
  if (props.fullscreen) {
    return (
      <>
        {props.banner && <Banner />}
        <div id="contents">
          <div className="wrapper">
            <div className="container">
              <div className="contents-area">{props.children}</div>
            </div>
          </div>
        </div>
      </>
    );
  }
  return (
    <>
      <div id="comp-sub-header" className="default">
        <div className="wrapper">
          <div className="container">
            <div className="title">"뇌 과학 시대의 뇌인지 연구소"</div>
          </div>
        </div>
      </div>

      <div id="contents">
        <div className="wrapper">
          <div className="container">
            <SideNavigation sideNav={props.sideNav} />
            <div className="contents-area">{props.children}</div>
          </div>
        </div>
      </div>
    </>
  );
}

function Banner() {
  return (
    <div id="comp-sub-header" className="main">
      <div className="wrapper">
        <div className="container">
          <div className="title">Brain OS Institute</div>
          <p>
            브레인OS연구소는 21세기가 뇌 과학의 시대임을 예견하고 2003년 교육
            분야를 시작으로 2008년 기업 컨설팅 분야에, 2014년 IT 분야로
            연구영역을 확대해온 "뇌인지 연구소"입니다.
          </p>
        </div>
      </div>
    </div>
  );
}

function SideNavigation(props) {
  if (props.sideNav) {
    return (
      <div id="side-nav">
        <CompSideNav />
      </div>
    );
  }
  return null;
}

function App() {
  const [isShowBanner] = useState(true);

  const [isShowFullscreen, setIsShowFullscreen] = useState(true);
  const [sideNavIndex, setSideNavIndex] = useState(0);
  const [sideNavCategory, setSideNavCategory] = useState("intro");
  const ctxValue = {
    isFullscreen: isShowFullscreen,
    sideNavIndex: sideNavIndex,
    sideNavCategory: sideNavCategory,
    setIsFullscreen: setIsShowFullscreen,
    setSideNavIndex: setSideNavIndex,
    setSideNavCategory: setSideNavCategory,
  };

  return (
    <BrowserRouter>
      <BosiContext.Provider value={ctxValue}>
        <CompHeader />

        <FullscreenLayout
          fullscreen={isShowFullscreen}
          sideNav={sideNavCategory !== null}
          banner={isShowBanner}
        >
          <Routes>
            <Route path="/" element={<Main />} />
            <Route path="/intro" element={<Intro />} />
            <Route path="/bosi" element={<BOSI />} />
            <Route path="/client" element={<Client />} />
            <Route path="/press" element={<Press />} />
            <Route path="/press/:postId" element={<PressDetail />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/terms/privacy" element={<TermsPrivacy />} />
            <Route path="/terms/service" element={<TermsService />} />
          </Routes>
        </FullscreenLayout>

        <CompFooter />
      </BosiContext.Provider>
    </BrowserRouter>
  );
}

export default App;
