import { useContext, useEffect, useState } from "react";
import { BosiContext } from "../constants/BosiContext";
import { CLIENT } from "../constants/Client";
import APIs from "../apis/APIs";

function ClientList(props) {
  if (!props.list) return null;

  return props.list.map((client, index) => {
    return (
      <li key={index}>
        {client.url !== null && <img src={client.url} />}
        {client.url === null && <span>{client.clientName}</span>}
      </li>
    );
  });
}

export default function Client() {
  const [orgList, setOrgList] = useState([]);
  const [busiList, setBusiList] = useState([]);
  const { setIsFullscreen, setSideNavCategory } = useContext(BosiContext);

  useEffect(() => {
    setIsFullscreen(false);
    setSideNavCategory(null);

    APIs.getClientList(CLIENT.CODE.Organization).then(({ clientList }) => {
      setOrgList(clientList);
    });
    APIs.getClientList(CLIENT.CODE.Business).then(({ clientList }) => {
      setBusiList(clientList);
    });

    return () => {
      setIsFullscreen(true);
    };
  }, []);

  return (
    <div id="client">
      <div className="section">
        <h1>클라이언트</h1>
        <p>
          브레인 OS 연구소에서는 뇌인지 성향을 분석하여 잠재역량을 파악하고
          최적의 미래를 탐색하는데 도움을 드리고 있습니다.
        </p>
      </div>
      <div className="section">
        <h1>학교 및 기관</h1>
        <ul>
          <ClientList list={orgList} />
        </ul>
      </div>
      <div className="section">
        <h1>기업</h1>
        <ul>
          <ClientList list={busiList} />
        </ul>
      </div>
    </div>
  );
}
