import { useContext } from "react";
import { CATEGORY } from "../constants/category";

import { BosiContext } from "../constants/BosiContext";

function getNavigation(category = "intro") {
  return CATEGORY.NAVIGATION[category.toUpperCase()];
}

function NavMenus(props) {
  const { sideNavCategory } = useContext(BosiContext);

  const navMenus = getNavigation(sideNavCategory).MENUS;
  return navMenus.map((menu, idx) => {
    return (
      <li key={menu} className={props.activeIndex === idx ? "active" : ""}>
        <a onClick={() => props.handleClick(idx)}>{menu}</a>
      </li>
    );
  });
}

function scrollToTop() {
  document.getElementById("root").scrollIntoView({ behavior: "smooth" });
}

const navMenuItemClick = (idxState, idx) => {
  document
    .getElementById(`section-${idx + 1}`)
    .scrollIntoView({ behavior: "smooth" });
  idxState(idx);
};

export default function CompSideNav() {
  const { sideNavIndex, setSideNavIndex, sideNavCategory } =
    useContext(BosiContext);

  return (
    <div id="comp-side-nav">
      <div className="category">{getNavigation(sideNavCategory).TITLE}</div>
      <ul>
        <NavMenus
          activeIndex={sideNavIndex}
          handleClick={navMenuItemClick.bind(this, setSideNavIndex)}
        />
        <li>
          <a onClick={() => scrollToTop()} className="top-btn">
            Top
          </a>
        </li>
      </ul>
    </div>
  );
}
