import { useRef, useState } from "react";
import { useNavigate } from "react-router";
import { useDetectOutsideClick } from "../constants/useDetectOutsideClick";

function Menu(props) {
  return (
    <li className="collapse-item">
      <div className="collapse-header">
        <a className="menu" onClick={props.handleClick}>
          {props.title}
        </a>
      </div>
      <div className="collapse-body"></div>
    </li>
  );
}

function MenuItem(props) {
  return (
    <li>
      <a onClick={props.handleClick}>{props.title}</a>
    </li>
  );
}

const IntroDropdownMenu = () => {
  const dropdownRef = useRef(null);
  const [isActive, setActive] = useDetectOutsideClick(dropdownRef, false);
  const onClick = () => setActive(!isActive);

  const navigate = useNavigate();

  return (
    <>
      <li
        ref={dropdownRef}
        className={`collapse-item ${isActive ? "show-contents" : ""}`}
      >
        <div className="collapse-header" onClick={onClick}>
          <a className="menu">브레인 OS 연구소</a>
        </div>
        <div className="collapse-body">
          <ul className="collapse-contents">
            <MenuItem
              title="연구소 소개"
              handleClick={() => navigate("/intro")}
            />
            <MenuItem
              title="인사말"
              handleClick={() =>
                navigate({
                  pathname: "/intro",
                  search: "?idx=2",
                })
              }
            />
            <MenuItem
              title="히스토리"
              handleClick={() =>
                navigate({
                  pathname: "/intro",
                  search: "?idx=3",
                })
              }
            />
          </ul>
        </div>
      </li>
    </>
  );
};

const BOSIDropdownMenu = () => {
  const dropdownRef = useRef(null);
  const [isActive, setIsActive] = useDetectOutsideClick(dropdownRef, false);
  const onClick = () => setIsActive(!isActive);

  const navigate = useNavigate();

  return (
    <li
      ref={dropdownRef}
      className={`collapse-item + ${isActive ? "show-contents" : ""}`}
    >
      <div className="collapse-header" onClick={onClick}>
        <a className="menu">뇌인지 적성검사</a>
      </div>
      <div className="collapse-body">
        <ul className="collapse-contents">
          <MenuItem title="BOSI 소개" handleClick={() => navigate("/bosi")} />
          <MenuItem
            title="타 심리검사와 차이점"
            handleClick={() =>
              navigate({
                pathname: "/bosi",
                search: "?idx=2",
              })
            }
          />
          <MenuItem
            title="검사의 종류"
            handleClick={() =>
              navigate({
                pathname: "/bosi",
                search: "?idx=3",
              })
            }
          />
          <MenuItem
            title="학생용 검사의 활용"
            handleClick={() =>
              navigate({
                pathname: "/bosi",
                search: "?idx=4",
              })
            }
          />
          <MenuItem
            title="성인용 검사 영역과 활용"
            handleClick={() =>
              navigate({
                pathname: "/bosi",
                search: "?idx=5",
              })
            }
          />
          <MenuItem
            title="검사 진행 절차"
            handleClick={() =>
              navigate({
                pathname: "/bosi",
                search: "?idx=6",
              })
            }
          />
        </ul>
      </div>
    </li>
  );
};

function navToggle(e, flag, setState) {
  e.preventDefault();
  setState(!flag);
}

function navOff(e, setState) {
  if (typeof e === "undefined") return;

  e.preventDefault();
  setState(false);
}

export default function CompHeader() {
  const [isShowNavMain, setShowNavMain] = useState(false);
  const navigate = useNavigate();

  window.addEventListener("resize", () => {
    let browserWidth = document.body.clientWidth;
    if (browserWidth >= 768) {
      navOff();
    }
  });

  return (
    <div id="comp-header">
      <div className="wrapper">
        <div className="container">
          <div className="logo" onClick={() => navigate("/")}>
            <span>브레인OS연구소</span>
          </div>
          <div
            id="nav-toggle"
            onClick={(e) => navToggle(e, isShowNavMain, setShowNavMain)}
          ></div>
          <ul
            id="nav-main"
            className={"collapse-list" + (isShowNavMain ? " show" : "")}
          >
            {/* Mobile */}
            <li className="mobile-only">
              <button onClick={(e) => navOff(e, setShowNavMain)}></button>
              <button
                className="close-nav"
                onClick={(e) => navOff(e, setShowNavMain)}
              ></button>
            </li>

            {/* Menus */}
            <IntroDropdownMenu />

            <BOSIDropdownMenu />

            <Menu title="클라이언트" handleClick={() => navigate("/client")} />
            <Menu title="보도자료" handleClick={() => navigate("/press")} />
            <Menu title="Contact" handleClick={() => navigate("/contact")} />
          </ul>

          <div
            id="nav-overlay"
            onClick={(e) => navOff(e, setShowNavMain)}
          ></div>
        </div>
      </div>
    </div>
  );
}
