export const CATEGORY = {
  NAVIGATION: {
    INTRO: {
      TITLE: "브레인 OS 연구소",
      MENUS: ["연구소 소개", "인사말", "히스토리"],
    },
    BOSI: {
      TITLE: "뇌인지 적성검사",
      MENUS: [
        "뇌인지 적성검사 소개",
        "타 심리검사와 차이점",
        "검사의 종류",
        "학생용 검사의 활용",
        "성인용 검사의 활용",
        "검사 진행 절차",
      ],
    },
  },
};
